import {scrolEvents} from './default';

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
	
	scrolEvents(controller , scene);
    var swiper = new Swiper('.swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        grabCursor: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    });
    
    const v = new Vue({
        el: '#site-header',
        data: {
            menu: false
        }
    });
});